import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
// import Login from '../views/Login.vue'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'orders',
                component: () => import('../views/Orders.vue')
            },
        ]
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['auth/authenticated']) {
            next()
            return
        }
        next('/login')
    } else {
        if (store.getters['auth/authenticated'] && to.name == 'login') {
            next('/')
        }
        next()
    }
})

export default router
