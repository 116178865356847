import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: false,
        token: null
    },

    getters: {
        authenticated (state) {
            return state.authenticated
        },

        token (state) {
            return state.token
        },
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },

        SET_TOKEN (state, value) {
            state.token = value

            if (value) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + value
            } else {
                delete axios.defaults.headers.common['Authorization']
            }
        }
    },

    actions: {
        async login ({ commit }, credentials) {
            await axios.post('/auth/login', credentials).then(response => {
                commit('SET_AUTHENTICATED', true)
                commit('SET_TOKEN', response.data.token)
            }).catch(() => {
                commit('SET_AUTHENTICATED', false)
                commit('SET_TOKEN', null)
            })
        },

        async logout ({ commit }) {
            commit('SET_AUTHENTICATED', false)
            commit('SET_TOKEN', null)
        },
    }
}
