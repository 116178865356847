import { createApp } from 'vue'
import VueNumberFormat from 'vue-number-format'
import axios from 'axios'

import App from './App.vue'
import router from './router'
import './main.css'
import store from './store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['auth/token']

createApp(App)
.use(store)
.use(router)
.use(VueNumberFormat, {prefix: '', decimal: '.', thounsand: ','})
.mount('#app')
