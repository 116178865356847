<template>
	<div>
		<div class="wrapper">
			<div class="container-fluid">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'Home',
}
</script>
